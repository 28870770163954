import React, { useContext, useState, useEffect } from "react";
import StateContext from "../StateContext";
import { DropTarget } from "react-drag-drop-container";

function VrMarker(props) {
  const [divStyle, setDivStyle] = useState();
  const appState = useContext(StateContext);
  const { posY, posX, targId, scale } = props;
  const { isVrpersonActive } = appState;
  const [markerSize, setMarkerSize] = useState(1);

  useEffect(() => {
    const a = 2.6 / scale;
    setMarkerSize(a);
  }, [scale]);

  useEffect(() => {
    const newDivStyle = {
      top: posY + "%",
      left: posX + "%",
      transform: "scale(" + markerSize + ")",
    };
    setDivStyle(newDivStyle);
  }, [posX, posY, markerSize]);

  useEffect(() => {
    if (isVrpersonActive) {
      dragLeftElement();
    } else {
      const newDivStyle = {
        top: posY + "%",
        left: posX + "%",
        transform: "scale(" + markerSize + ")",
        transition: "all 200ms ease-in-out",
        border: "1px solid rgba(255, 255, 255, 0)",
      };
      setDivStyle(newDivStyle);
    }
  }, [isVrpersonActive, markerSize]);

  function dragEntered() {
    const newDivStyle = {
      top: posY + "%",
      left: posX + "%",
      transform: "scale(" + markerSize + ")",
      transition: "all 200ms ease-in-out",
      border: "1px solid #e36c09",
    };
    setDivStyle(newDivStyle);
  }

  function dragLeftElement() {
    const newDivStyle = {
      top: posY + "%",
      left: posX + "%",
      transform: "scale(" + markerSize + ")",
      transition: "all 200ms ease-in-out",
      border: "1px solid rgba(255, 255, 255, 1)",
    };

    setDivStyle(newDivStyle);
  }

  return (
    <>
      {isVrpersonActive ? (
        <DropTarget
          targetKey="vr_marker"
          dropData={targId}
          onDragEnter={dragEntered}
          onDragLeave={dragLeftElement}
        >
          <div className="vrMarker" style={divStyle} />
        </DropTarget>
      ) : null}
    </>
  );
}

export default VrMarker;
