import React, { useContext, useState, useEffect } from "react";
import StateContext from "../StateContext";
import DispatchContext from "../DispatchContext";
import { useHistory } from "react-router-dom";

function PlotMarker(props) {
  const [divStyle, setDivStyle] = useState();
  const appState = useContext(StateContext);
  const appDispatch = useContext(DispatchContext);

  const history = useHistory();
  const goToRoute = (location) => history.push(location);

  const { posY, posX, posZ, colour, plotId, plotNum, isNotMobile, scale } =
    props;

  const clamp = (num, min, max) => Math.min(Math.max(num, min), max);

  useEffect(() => {
    let markerSize = 1.5 / scale;
    markerSize = clamp(markerSize, 0.25, 1.0);

    const newDivStyle = {
      top: posY + "%",
      left: posX + "%",
      zIndex: posZ,
      transform: "scale(" + markerSize + ")",
      backgroundColor: colour,
    };
    setDivStyle(newDivStyle);
  }, [posX, posY, posZ, colour, scale]);

  function PlotMarkerClicked() {
    if (!appState.isPanning) {
      if (isNotMobile) {
        appDispatch({
          type: "showPlotPreview",
          data: { plotid: plotId, markerId: plotNum },
        });
      } else {
        goToRoute(`/plots/${plotId}`);
      }
    }
  }

  return (
    <button
      key={plotNum}
      id={plotNum}
      className="plotMarker"
      onClick={PlotMarkerClicked}
      style={divStyle}
    >
      {plotNum}
    </button>
  );
}

export default PlotMarker;
