import React, { useContext, useRef } from "react";
import { NavLink, useLocation } from "react-router-dom";
import StateContext from "../StateContext";
import DispatchContext from "../DispatchContext";

import { Squash as Hamburger } from "hamburger-react";

import { ReactComponent as AvailabilityIcon } from "../icons/availability-icon.svg";
import { ReactComponent as SpecificationIcon } from "../icons/specification-icon.svg";
import { ReactComponent as GalleryIcon } from "../icons/gallery-icon.svg";
import { ReactComponent as LocalAreaIcon } from "../icons/local-area-icon.svg";
// import { ReactComponent as FavouritesIcon } from "../icons/home-heart-light.svg"
import { ReactComponent as HomesIcon } from "../icons/home-icon.svg";
import { ReactComponent as SiteplanIcon } from "../icons/compass-icon.svg";
import { ReactComponent as VirtualTourIcon } from "../icons/virtual-tour-icon.svg";
import { ReactComponent as MasterPlanIcon } from "../icons/masterplan-icon.svg";
import { ReactComponent as DownloadsIcon } from "../icons/download-icon.svg";
import { ReactComponent as BookAppointmentIcon } from "../icons/book-appointment-icon.svg";
import { ReactComponent as HomeIcon } from "../icons/home-switch-outline.svg";

import { disableBodyScroll, enableBodyScroll } from "body-scroll-lock";

function Menu() {
  const appState = useContext(StateContext);
  const appDispatch = useContext(DispatchContext);
  const wrapperRef = useRef(null);

  //check for route change and close menu
  const location = useLocation();
  React.useEffect(() => {
    appDispatch({ type: "hideMenu" });
  }, [location]);

  const handleClick = () => {
    if (appState.isMenuOpen) {
      appDispatch({ type: "hideMenu" });
    } else {
      appDispatch({ type: "hideRefineMenu" });
      appDispatch({ type: "showMenu" });
    }
  };

  const useOutsideAlerter = (ref) => {
    function handleClickOutside(event) {
      if (
        appState.isMenuOpen &&
        ref.current &&
        !ref.current.contains(event.target) &&
        !event.target.classList.contains("hamburger-react")
      ) {
        appDispatch({ type: "hideMenu" });
      }
    }

    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("mousedown", handleClickOutside);
    };
  };
  useOutsideAlerter(wrapperRef);

  return (
    <>
      <div
        className={
          "off-screen-menu " +
          (appState.isMenuOpen ? "off-screen-menu--is-visible" : "")
        }
        ref={wrapperRef}
      >
        <div className="off-screen-menu__content">
          <div className="main-menu">
            <div className="menu-item">
              <NavLink to="/types">
                <div className="menu-item__icon">
                  <HomesIcon />
                </div>
                <span className="menu-item__label">House Types</span>
              </NavLink>
            </div>

            <div className="menu-item">
              <NavLink to="/plots">
                <div className="menu-item__icon">
                  <AvailabilityIcon />
                </div>
                <span className="menu-item__label">Availability</span>
              </NavLink>
            </div>

            {appState.devMenuItems && appState.devMenuItems.brochure ? (
              <div className="menu-item">
                <NavLink to="/brochure">
                  <div className="menu-item__icon">
                    <SpecificationIcon />
                  </div>
                  <span className="menu-item__label">Brochure</span>
                </NavLink>
              </div>
            ) : null}

            {appState.devMenuItems && appState.devMenuItems.brochures ? (
              <div className="menu-item">
                <NavLink to="/brochure">
                  <div className="menu-item__icon">
                    <SpecificationIcon />
                  </div>
                  <span className="menu-item__label">Brochures</span>
                </NavLink>
              </div>
            ) : null}

            <div className="menu-item">
              <NavLink to="/local-area">
                <div className="menu-item__icon">
                  <LocalAreaIcon />
                </div>
                <span className="menu-item__label">Local Area</span>
              </NavLink>
            </div>

            {/* <div className="menu-item">
              <NavLink to="/favourites">
                <div className="menu-item__icon">
                  <FavouritesIcon />
                  <h2>Favourites</h2>
                <span className="menu-item__label">
              </NavLink>
            </div> */}

            {appState.galleryData.length >= 1 && (
              <div className="menu-item">
                <NavLink to="/gallery">
                  <div className="menu-item__icon">
                    <GalleryIcon />
                  </div>
                  <span className="menu-item__label">Gallery</span>
                </NavLink>
              </div>
            )}

            {appState.devMenuItems && appState.devMenuItems.virtualTour ? (
              <div className="menu-item">
                <NavLink to="/exterior-tour">
                  <div className="menu-item__icon">
                    <VirtualTourIcon />
                  </div>
                  <span className="menu-item__label">Virtual Tour</span>
                </NavLink>
              </div>
            ) : null}

            {appState.devMenuItems && appState.devMenuItems.virtualTours ? (
              <div className="menu-item">
                <NavLink to="/exterior-tour">
                  <div className="menu-item__icon">
                    <VirtualTourIcon />
                  </div>
                  <span className="menu-item__label">Virtual Tours</span>
                </NavLink>
              </div>
            ) : null}

            {appState.globalConfigData ? (
              <div className="menu-item">
                <NavLink to="/360spin/0">
                  <div className="menu-item__icon">
                    <SiteplanIcon />
                  </div>
                  {appState.globalConfigData.spins.length > 1 ? (
                    <span className="menu-item__label">Site Plans</span>
                  ) : (
                    <span className="menu-item__label">Site Plan</span>
                  )}
                </NavLink>
              </div>
            ) : null}

            {appState.devMenuItems && appState.devMenuItems.masterPlan ? (
              <div className="menu-item">
                <NavLink to="/masterplan">
                  <div className="menu-item__icon">
                    <MasterPlanIcon />
                  </div>
                  <span className="menu-item__label">Master Plan</span>
                </NavLink>
              </div>
            ) : null}

            {appState.devMenuItems && appState.devMenuItems.masterPlans ? (
              <div className="menu-item">
                <NavLink to="/masterplan">
                  <div className="menu-item__icon">
                    <MasterPlanIcon />
                  </div>
                  <span className="menu-item__label">Master Plans</span>
                </NavLink>
              </div>
            ) : null}

            {appState.devMenuItems && appState.devMenuItems.downloads ? (
              <div className="menu-item">
                <NavLink to="/downloads">
                  <div className="menu-item__icon">
                    <DownloadsIcon />
                  </div>
                  <span className="menu-item__label">Downloads</span>
                </NavLink>
              </div>
            ) : null}

            {/* {appState.devMenuItems && appState.devMenuItems.bookAppointment ? (
              <div className="menu-item">
                <NavLink to="/book-appointment">
                  <div className="menu-item__icon">
                    <BookAppointmentIcon />
                  </div>
                  <span className="menu-item__label">Book Appointment</span>
                </NavLink>
              </div>
            ) : null} */}

            <div className="menu-item">
              <a href="https://vr-storage-thinkbdw.s3.eu-west-2.amazonaws.com/bellway/SalesCentre/Sales%20Centre.html">
                <div className="menu-item__icon">
                  <HomeIcon />
                </div>
                <span className="menu-item__label">Sales Centre</span>
              </a>
            </div>
          </div>
        </div>
      </div>

      <div className="menu-toggle">
        <Hamburger
          rounded
          color="#ffffff"
          size={20}
          toggle={handleClick}
          toggled={appState.isMenuOpen}
        />
      </div>
    </>
  );
}

export default Menu;
