import React, { useContext, useState, useEffect, useRef } from "react"
import { TransformWrapper, TransformComponent, ReactZoomPanPinchRef } from "react-zoom-pan-pinch"
import { motion, AnimatePresence } from "framer-motion"
import { useMediaQuery } from "react-responsive"
import Page from "../components/Page"
import StateContext from "../StateContext"
import DispatchContext from "../DispatchContext"
import ZoneMarkerBP from "../components/ZoneMarkerBP"
import PlotMarkerBP from "../components/PlotMarkerBP"
import StatusKeyItem from "../components/StatusKeyItem"
import RefineMenu from "../components/RefineMenu"
import PlotPreview from "../components/PlotPreview"

import {
  Switch,
  Route,
  useParams,
  useRouteMatch,
  useLocation,
  NavLink
} from "react-router-dom"


function Blockplan(){

const transformComponentRef = useRef(null)

const { url, path } = useRouteMatch()
const appState = useContext(StateContext)
const appDispatch = useContext(DispatchContext)

const [blockPlanImage, setBlockplanImage] = useState('')
const [zoneMarkers, setZoneMarkers] = useState([])
const [plotMarkers, setPlotMarkers] = useState([])
const [thisBuildingName, setThisBuildingName] = useState('')
const [cgiImage, setCgiImage] = useState('')
const [cgiImagePath, setCgiImagePath] = useState('')
const [displayCgiImage, setDisplayCgiImage] = useState(false)

const [floorsArray, setFloorsArray] = useState([])
const [floorLabel, setFloorLabel] = useState("")

const [scale, setScale] = useState(1.0);

const id = useParams().id



const [thisUrl, setThisUrl] = useState()

const [selectedTargetId, setSelectedTargetId] = useState()
const [containerType, setContainerType] = useState()
const [thisVisibility, setThisVisibility] = useState()
const [plotStatusArray, setPlotStatusArray] = useState([])

const [containerStyle, setContainerStyle] = useState()

const [currentFloorNumber, setCurrentFloorNumber] = useState(0)
const [prevFloorNumber, setPrevFloorNumber] = useState(0)

const [floorDirection, setDirection] = useState(0);

const isDesktopNotMobile = useMediaQuery(
  { minWidth: 992 },
  undefined
)

var interactiveTimer

useEffect(()=>{
console.log('thisUrl ' + thisUrl)
console.log('path ' + path)

})

useEffect(() => {
  if(isDesktopNotMobile){
    document.documentElement.style.setProperty("--frame-width", 1000 )
  }else{
    //todo add a frame width value for mobile (need to set all markers etc to scale too)
    document.documentElement.style.setProperty("--frame-width", 1000 )
  }
}, [isDesktopNotMobile])

useEffect(()=>{
  
  zoomToPlotMarker()

},[appState.selectedPlotMarkerId])

const animateFloorplan = (newDirection) => {
  setDirection(newDirection);
}


const variants = {
  enter: (floorDirection) => {

    //console.log('containertype = ' + `${containerType}`)
    return {
      zIndex: 1,
      y: floorDirection < 0 ? "50%" : "-50%",
      scale: 0.4,
      opacity: 0.2
    }
  },
  center: {
    y: 0,
    scale: `${containerType}`.includes("Block") ? 0.75 : 1.0,
    opacity: 1.0
  },
  exit: () => {
    return {
      zIndex: 0,
      y: floorDirection > 0 ? "50%" : "-50%",
      scale: 0.4,
      opacity: 0.2
    }
  }
}

useEffect(() => {
  window.addEventListener('resize', (event) => {
    CenterView()
  })
  
  return () => {
    window.removeEventListener('resize', (event) => {
      CenterView()
    })
  }
},[])


useEffect(() => {
  if(scale == 1)
    appDispatch({ type: "closePlotPreview" })

},[scale] )


useEffect(() => {
  document.title = `Blockplan | ${appState.devName}`
  window.scrollTo(0, 0)
  setThisUrl(url)
  //console.log('current url is ' + url)
  drawStatusKey()
  DisplaySite()
},[])


useEffect(() => {
  CenterView()
},[blockPlanImage])


useEffect(() => {
  HighlightElevationButton(selectedTargetId)
},[selectedTargetId])


useEffect(() => {
  setCgiImage(cgiImagePath)
},[displayCgiImage])


useEffect(()=>{
//console.log('current floor number ' + currentFloorNumber + ' prev ' + prevFloorNumber)
  if(currentFloorNumber < prevFloorNumber){
    //console.log('going down')
    animateFloorplan(-1)

  }else{
    //console.log('going up')
    animateFloorplan(1)
  }
  setPrevFloorNumber(currentFloorNumber)

},[currentFloorNumber])


useEffect(()=>{
  
  if(!appState.isPreviewPanelOpen)
    CenterView()
  
},[appState.isPreviewPanelOpen])


useEffect(() => {
    getPlotContainerDataForId(appState.plotContainers, selectedTargetId);
},[appState.filteredHomes])


function getPlotContainerDataForId(obj, targetId){

    obj.forEach(function (plotContainerData){
      if (plotContainerData.id == targetId){
        var imageUrl = plotContainerData.interactiveAssetURI
        var interactiveRegions = plotContainerData.interactiveRegions
        var containerType = plotContainerData.plotContainerType.name
        var doesNotContainBlockplan = plotContainerData.plotIds.length >0

        setContainerType(containerType)
        setCgiImagePath(plotContainerData.cgiAssetURI)

        if(plotContainerData.name != 'Site Plan'){
          setFloorLabel(plotContainerData.name)
          setCurrentFloorNumber(plotContainerData.floorNumber)
        }
        //setThisPageName(plotContainerData.name)

      if(containerType == "Block"){

        setDisplayCgiImage(true)
        setThisBuildingName(plotContainerData.name)
        
        var tempArray = []

        interactiveRegions.forEach(function (thisRegion){
          var thisId = thisRegion.targetId
          let x = plotContainerData.plotContainers.find(thisX => thisX.id == thisRegion.targetId)
          //console.log( thisRegion.targetId +' floor number = ' + x.floorNumber)
          tempArray.push({
            floorNumber: x.floorNumber,
            floorLabel: x.name,
            targetId: thisRegion.targetId
          })
        })

        const outputElevationArray = [].concat(tempArray).sort((b, a) => parseFloat(a.floorNumber) - parseFloat(b.floorNumber))

        //this is the 1st floor to display
        var startingFloorTargetId = outputElevationArray[outputElevationArray.length -1].targetId

        setFloorsArray(outputElevationArray);
        //getPlotContainerDataForId(plotContainerData.plotContainers, interactiveRegions[0].targetId)
        getPlotContainerDataForId(plotContainerData.plotContainers, startingFloorTargetId)

      }else{
        
        buildBlockView(imageUrl, interactiveRegions, containerType, doesNotContainBlockplan)
        setSelectedTargetId(targetId);//to enable hi light of elevation marker [rich 24082021]
      return false;
      
      }
      
    }

    if (plotContainerData.hasOwnProperty('plotContainers')){
      getPlotContainerDataForId(plotContainerData.plotContainers, targetId)
    }

  })
    
}


function zoomToPlotMarker() {
  
  if(appState.selectedPlotMarkerId){
    var thisMarker = document.getElementById(appState.selectedPlotMarkerId)
    
    if(transformComponentRef.current !=null){
      const { zoomToElement } = transformComponentRef.current

      var zoomToScale = 2
      if(scale >2 )
      zoomToScale = scale

      if(appState.plotPreviewContent !=null){
        zoomToElement( thisMarker , zoomToScale, 3000, 'easeOut')
      }
    }
  } 
}


function detailsPanel(thisState){

  const newDivStyle = {
    visibility: thisState,
    bottom: '30px'
    }
  
    setThisVisibility(newDivStyle);
}


function buildBlockView(imageUrl, interactiveRegionsArray, containerType, isJustSiteplan){

  setPlotMarkers([])
  setZoneMarkers([])
  var plotRegionsTempArray = []
  var zoneRegionsTempArray = []

  if(imageUrl !=null){
    var thisImage = new Image()
    thisImage.onload = loaded
    thisImage.src = imageUrl

    async function loaded() {
      //console.log('image ' + this.width + 'x' + this.height)
      setBlockplanImage(imageUrl)
  
      //sanitize the output so I can apply filter an get plot colours [rich 02082021]
      interactiveRegionsArray.forEach(function (interactiveRegion) {
        
        var posX = interactiveRegion.startX
        var posY = interactiveRegion.startY
        var width = interactiveRegion.width
        var height = interactiveRegion.height
        var targId = interactiveRegion.targetId
        var isZoneMarker = interactiveRegion.isTargetAContainer
        
        if(isZoneMarker){
          zoneRegionsTempArray.push({
            x: posX,
            y: posY,
            width: width,
            height: height,
            id: targId
          })
        }else{
          //let thisPlotInfo = appState.plotsData.find(plotItem => plotItem.id == interactiveRegion.targetId) //show all plot markers for dev
          let thisPlotInfo = appState.filteredHomes.find(plotItem => plotItem.id == interactiveRegion.targetId) //show filtered plot markers
          
          if(thisPlotInfo){
            let thiPlotStatus = appState.plotStatuses.find(statusItem => statusItem.id == thisPlotInfo.plotStatusId)
        
            //Think Hub data sometimes has duplicate information (Residence) so only add to array if unique plot id [rich 02082021]
            if(!plotRegionsTempArray.find(thisRegion => thisRegion.id == targId)){
              
              plotRegionsTempArray.push({
                x: posX,
                y: posY,
                width: width,
                height: height,
                id: targId,
                plotNumber: thisPlotInfo.plotNumber,
                colour: thiPlotStatus.color
              })
            }
          }
        }
      })
      
      if(plotRegionsTempArray.length >0){
        detailsPanel('visible')
      }else{
        detailsPanel('hidden')
      }
      
      setPlotMarkers(plotRegionsTempArray)
      setZoneMarkers(zoneRegionsTempArray)
    }
  }
}


function HighlightElevationButton(thisTargetID){
  
  var highlightColour = getComputedStyle(document.documentElement).getPropertyValue('--theme-primary-color');

  if(floorsArray.length >0){
    floorsArray.forEach(function (thisFloor){

      var element = document.getElementById(thisFloor.targetId);

      if(thisFloor.targetId == thisTargetID){
        element.style="background-Color: " + highlightColour + ";"
      }else{
        element.style="background-Color: 888;"
      }

    })
  }
}


function DisplayElevation(thisTarget){
  getPlotContainerDataForId(appState.plotContainers, thisTarget)
}


function DisplaySite(){

  var targetID = appState.plotContainers[0].id
  
  if (id != 0)
    targetID = id

  getPlotContainerDataForId(appState.plotContainers, targetID)

}


function drawStatusKey(){

  var tempStatusArray = []
  appState.plotStatuses.forEach(function (aPlotStatus){

    if(aPlotStatus.name !='Hidden'){

      tempStatusArray.push({
        statusLabel: aPlotStatus.name,
        statusColour: aPlotStatus.color
      })
    }

  })
  setPlotStatusArray(tempStatusArray);
}


function ZoomOut(){

  var thisScale = parseFloat(`${scale}`)
 
  if(thisScale > 1.0)
    thisScale = thisScale - 1.0

  setZoomValue(thisScale)
}


function ZoomIn(){
  
  var thisScale = parseFloat(`${scale}`)
  
  if(thisScale < 8.0)
    thisScale = thisScale + 1.0

  setZoomValue(thisScale)
}


function setZoomValue(thisValue){

  const targetScale = parseFloat(thisValue);
  const factor = Math.log(targetScale / scale)
  const { zoomIn, zoomOut } = transformComponentRef.current

  if (targetScale > scale) {
    zoomIn(factor, 300)
  } else {
    zoomOut(-factor, 300)
  }
  setScale(targetScale)
}


function CenterView(){

  if(transformComponentRef.current !=null){
    const { centerView } = transformComponentRef.current
    centerView(1, 1000, 'easeOut')
  }
}


function StartInteractiveTimeout(){

  clearInterval(interactiveTimer)
  interactiveTimer = setInterval(function() {EnableMarkerInteraction(); }, 200)
}


function EnableMarkerInteraction(){
  clearInterval(interactiveTimer)
  appDispatch({ type: "isPanning", data: false})
}


return (
  <Page title="Blockplan" container={false}>
  <motion.div
  className="MainWrapper"
  key={'MainWrapper'}
  animate={{
    width: appState.isPreviewPanelOpen ? 70 + 'vw' : 100 + 'vw'
  }}
  onAnimationComplete={appState.isPreviewPanelOpen ? zoomToPlotMarker : CenterView}
  transition={{
    x: { type: "spring", stiffness: 300, damping: 40 }
  }}
  >

    <React.Fragment>
      <div className="elevationMenu">
        {floorsArray.map(thisFloor => (
        <div key={thisFloor.floorNumber.toString()}><div><button id={thisFloor.targetId} className="elevationMarker" onClick={() => DisplayElevation(`${thisFloor.targetId}`)}>{thisFloor.floorNumber}</button></div></div>
        ))}
      </div>
    </React.Fragment>

      <React.Fragment>
        <div className="status-container">
          {plotStatusArray.map(thisStatusItem => (
          <div className="status-item"  key={thisStatusItem.statusLabel}><StatusKeyItem label={thisStatusItem.statusLabel} colour={thisStatusItem.statusColour}/></div>
          ))}
        </div>
      </React.Fragment>

      <img className="cgiImage" src={cgiImage} alt={thisBuildingName}/>

      {!appState.isPreviewPanelOpen ?
      <React.Fragment>
        <div className="info-frame">
          <div className="building-text">{thisBuildingName}</div>
          <div className="floor-label">{floorLabel}</div>
        </div>
      </React.Fragment>
      : null}

      <TransformWrapper
        ref = {transformComponentRef}
        initialScale={scale}
        onZoomStop={(e) => {
            setScale(e.state.scale);
        }}

        onPanning={(e)=>{
          appDispatch({ type: "isPanning", data: true})
        }}

        onPanningStop={(e)=>{
          StartInteractiveTimeout()
        }}

        centerOnInit
        onZoom={(e) => {
          setScale(e.state.scale);
        }}>
          
        {({ zoomIn, zoomOut, resetTransform, centerView, ...rest }) => (
          <React.Fragment>
            {!appState.isPreviewPanelOpen ? 
            <div className="zoomControlPanel">
            <button className="zoomInButton" onClick={() => ZoomIn()}>+</button>
            <button className="zoomOutButton" onClick={() => ZoomOut()}>-</button>
            <button className="centerSpinButton" onClick={() => CenterView()}>C</button>
            </div>
            : null }
            <TransformComponent
              wrapperStyle={{
                width: "100%",
                height: "100%"
              }}
              >
              <AnimatePresence  initial={true} cutom={floorDirection} exitBeforeEnter={true}>
                <motion.div
                  className="zoomContainer"
                  key={blockPlanImage}
                  custom={floorDirection}
                  variants={variants}
                  initial="enter"
                  animate="center"
                  exit="exit"
    
                  transition={{
                    y: { type: "spring", stiffness: 300, damping: 40 }
                  }}
                  >
                  <img src={blockPlanImage} alt={blockPlanImage}/>
                  {zoneMarkers.map(zoneMarker => (
                  <div key={zoneMarker.id.toString()}><ZoneMarkerBP
                                                        containerType={containerType}
                                                        posX={zoneMarker.x}
                                                        posY={zoneMarker.y}
                                                        markerWidth={zoneMarker.width}
                                                        markerHeight={zoneMarker.height}
                                                        name={zoneMarker.id}
                                                        targId={zoneMarker.id}
                                                        url={thisUrl}
                                                        />
                  </div>
                  ))}
              
                  {plotMarkers.map(plotMarker => (
                  <div key={plotMarker.id.toString()}><PlotMarkerBP
                                                        containerType={containerType}
                                                        posX={plotMarker.x}
                                                        posY={plotMarker.y}
                                                        markerWidth={plotMarker.width}
                                                        markerHeight={plotMarker.height}
                                                        plotId={plotMarker.id}
                                                        plotNum={plotMarker.plotNumber}
                                                        plotColour={plotMarker.colour}
                                                        />
                  </div>
                  ))}
                </motion.div>
              </AnimatePresence>
            </TransformComponent>
          </React.Fragment>
        )}
      </TransformWrapper>
    </motion.div>
    <PlotPreview />
  {!appState.isPreviewPanelOpen ?
    <RefineMenu />
    : null}
    

    <Route path={`${path}/blockplan/:id`} component={Blockplan} />
    
  </Page>
)
  
}

export default Blockplan



/*
<React.Fragment>
<div className="infoFrame" style={thisVisibility}>
<div className="buildingText"><a>{thisBuildingName}</a></div>
<div className="floorLabel">{floorLabel}</div>
</React.Fragment>
*/
