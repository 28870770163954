import React,{useState, useEffect} from 'react'

function RoadMarker(props) {
  const { posY,  posX, posZ, name, scale} = props
  const [divStyle, setDivStyle] = useState()

  const clamp = (num, min, max) => Math.min(Math.max(num, min), max)
  
  useEffect(()=>{
    
    let markerSize = .8 / scale
    markerSize = clamp(markerSize, 0.25, 1.0)
  
    const newDivStyle = {
      top: posY + '%',
      left: posX + '%',
      zIndex: posZ,
      transform: "scale(" + markerSize + ")"
    }
    setDivStyle(newDivStyle)

  },[posX, posY, posZ, scale])

  return (  
    <button className="roadMarker" style={divStyle}>{name}</button>
  )
}

export default RoadMarker
