import React, { useState, useContext, useEffect } from "react"
import { useHistory } from "react-router-dom"
import StateContext from "../StateContext"
import DispatchContext from "../DispatchContext"

import { disableBodyScroll, enableBodyScroll } from "body-scroll-lock"
import Loading from "./Loading"
import Carousel from "react-bootstrap/Carousel"

import { dynamicSort } from "./Helpers"

import { ReactComponent as CloseIcon } from "../icons/times-light.svg"

import PlotDetails from "./PlotDetails"

import Badge from "react-bootstrap/Badge"
import { motion } from "framer-motion"

function PlotPreview({ layout = 'two-col', ...props }) {
  const appState = useContext(StateContext)
  const appDispatch = useContext(DispatchContext)

  //const [isAnimating, setIsAnimating] = useState(false)

  //check for route change and close popover
  // const location = useLocation()
  // React.useEffect(() => {
  //   appDispatch({ type: "hidePopover" })
  //   console.log("location change")
  // }, [location])

  const history = useHistory()
  const goToRoute = (location) => history.push(location)

  //add body class to lock scroll when popover is open
  React.useEffect(() => {
    if (appState.isPopoverOpen === true) {
      disableBodyScroll(document.querySelector(".off-screen-popover-content"))
    } else {
      enableBodyScroll(document.querySelector(".off-screen-popover-content"))
      // clearAllBodyScrollLocks()
    }
  }, [appState.isPopoverOpen])
/*
  function closePopover() {
    appDispatch({ type: "hidePopover" })
  }*/

  function visitLink(location) {
    //appDispatch({ type: "hidePopover" })
    appDispatch({ type: "closePlotPreview" })
    enableBodyScroll(document.querySelector(".off-screen-popover-content"))
    goToRoute(location)
  }

  // function openPopover() {
  //   appDispatch({ type: "showPopover" })
  // }

  //plot data
  const currentPlotID = parseInt(appState.plotPreviewContent)
  const [currentPlotData, setCurrentPlotData] = useState()
  const [currentPlotStatus, setCurrentPlotStatus] = useState()

  useEffect(() => {
    appState.plotsData.filter((el) => {
      if (el.id === currentPlotID) {
        //order images
        let sortedImages = el.plotType.assets.slice().sort(dynamicSort("order"))
        el = { ...el, sortedImages }

        //order dims
        let sortedDims = el.plotType.dimensions
          .slice()
          .sort(dynamicSort("orderValue"))
        el = { ...el, sortedDims }

        setCurrentPlotData(el)

        let status = appState.plotStatuses.filter((status) => {
          if (status.id === el.plotStatusId) {
            return el
          }
          return null
        })[0]

        setCurrentPlotStatus(status)
      }
      return null
    })
  }, [appState.plotStatuses, appState.plotsData, currentPlotID])

  
  //Update light server
  useEffect(() => {
    if (appState.isPopoverOpen === true) {
      if (currentPlotData) {
        appDispatch({
          type: "setCurrentLight",
          data: { ID: currentPlotData.lightId, status: "on" }
        })
      }
    } else {
      appDispatch({
        type: "turnOffCurrentLight"
      })
    }
  }, [appState.isPopoverOpen, currentPlotData])
  

  return (
   
	<motion.div
   	className={`plot-preview-panel plot-preview-panel--${layout}`}
	  key="plot-preview-panel"
  	 animate={{
  	  x: appState.isPreviewPanelOpen ? 70 + 'vw' : 130 + 'vw',
  	  opacity: appState.isPreviewPanelOpen ? 1.0 : 0.0
    
  	}}
  	initial={{
    	opacity: 0.1
  	}}
    transition={{
      x: { type: "spring", stiffness: 250, damping: 45 }
    }}
  	>
      <div>
        {currentPlotData ? (
          <div className="row mt-3">
            <div className="col-md-4">
              <h2 className="plot-name">Plot {currentPlotData.plotNumber}</h2>
              <h4 className="house-name">{currentPlotData.name}</h4>

              <PlotDetails item={currentPlotData} status={currentPlotStatus} />
              {/* <p className="house-description">{currentPlotData.description}</p> */}
              <button
                className="btn btn-primary mb-4 mt-1"
                onClick={() => visitLink(`/plots/${currentPlotData.id}`)}
              >
                View Home
              </button>
            </div>
            <div className="col-md-8">
              <div className="carousel-wrap">
                <div className="overlay-badge">
                  <Badge
                    pill
                    variant="light"
                    className="mb-2"
                    style={{ color: currentPlotStatus.color }}
                  >
                    {currentPlotStatus.name}
                  </Badge>{" "}
                </div>
                <Carousel interval={null} controls={false}>
                  {currentPlotData.sortedImages.map((img) => {
                    return (
                      <Carousel.Item key={img.assetId}>
                        <img
                          className="d-block w-100"
                          src={img.fileUrl}
                          alt=""
                        />
                        {/* <Carousel.Caption>
                      <p>{img.name}</p>
                    </Carousel.Caption> */}
                      </Carousel.Item>
                    )
                  })}
                </Carousel>
              </div>

              <p className="small mt-5">
                {currentPlotData.plotType?.disclaimerText}
              </p>
            </div>
            <div className="plot-preview-close-button" onClick={()=>{appDispatch({ type: "closePlotPreview" })}}>
        
          <CloseIcon />
        
      </div>
          </div>
        ) : (
          <Loading />
        )}
      </div>

    </motion.div>
  )
}

export default PlotPreview
