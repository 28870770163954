import React, { useContext } from "react"
import { Link } from "react-router-dom"
import StateContext from "../StateContext"

import { ReactComponent as BellwayLogo } from "../icons/bellway-logo.svg"

function Header() {
  const appState = useContext(StateContext)

  return (
    <>
      <div className="site-header">
        <h1>
          <Link to="/">
            <span className="light">Discover</span> {appState.devName}
          </Link>
        </h1>

        <div className="bellway-logo">
          <BellwayLogo/>
        </div>
 
      </div>
    </>
  )
}

export default Header
