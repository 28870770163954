import React, { useContext, useState, useEffect, useRef } from "react";
import DispatchContext from "../DispatchContext";
import GetSpinDataFrame from "../components/GetSpinDataFrame";
import StateContext from "../StateContext";
import PlotPreview from "../components/PlotPreview";
import Page from "../components/Page";
import StatusKeyItem from "../components/StatusKeyItem";
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";
import { DragDropContainer } from "react-drag-drop-container";
import { useHistory } from "react-router-dom";
import { useMediaQuery } from "react-responsive";
import { motion } from "framer-motion";
import Controls from "../components/Controls";
import ItemMenu from "../components/ItemMenu";
import { disableBodyScroll, clearAllBodyScrollLocks } from "body-scroll-lock";
import { ReactComponent as ZoomControlPlus } from "../icons/zoom-control-plus.svg";
import { ReactComponent as ZoomControlMinus } from "../icons/zoom-control-minus.svg";
import { ReactComponent as IconCenter } from "../icons/zoom-control-center.svg";
import { ReactComponent as VrPerson } from "../icons/vr-person.svg";
import { ReactComponent as Compass } from "../icons/site-compass.svg";

import { useLocation } from "react-router-dom";

let enableSpin = true;
let doOnceFlag = false;

function SiteplanSpin(props) {
  let { siteId } = props;
  const history = useHistory();

  const appState = useContext(StateContext);
  const appDispatch = useContext(DispatchContext);
  const goToRoute = (location) => history.push(location);

  const location = useLocation();

  let interactiveTimer;

  const transformComponentRef = useRef(null);
  const motionFrameRef = useRef(null);

  const [isPressed, setIsPressed] = useState(false);

  const {
    globalConfigData,
    spinConfigData,
    selectedPlotMarkerId,
    isPreviewPanelOpen,
    devName,
    devSpinPath,
    devID,
    plotPreviewContent,
    plotStatuses,
  } = appState;

  const [disablePan, setDisablePan] = useState(true);
  const [scale, setScale] = useState(1);
  const [plotStatusArray, setPlotStatusArray] = useState([]);
  const [thisSpinConfig, setThisSpinConfig] = useState(false);
  const [multipleItems, setMultipleItems] = useState(false);
  const [currentItem, setCurrentItem] = useState(false);

  const isDesktopNotMobile = useMediaQuery({ minWidth: 992 }, undefined);

  const clamp = (num, min, max) => Math.min(Math.max(num, min), max);

  const [angles, setAngles] = useState({
    rotation: 0,
    tilt: 0,
    lastRotation: 0,
    lastTilt: 0,
    compassOffset: 0,
    res: "4k",
  });

  /*
  useEffect(() => {
    //for developemnt debug
    console.log("angles ", angles);
  }, [angles]);
  */

  const [frameNumberData, setFrameNumberData] = useState({
    frameString: "",
    frameNumber: 0,
  });

  const [position, setPosition] = useState({
    prevX: 0,
    prevY: 0,
    deltaX: 0,
    deltaY: 0,
  });

  const spinImageUrl = `${devSpinPath}${devID}/${siteId}/${angles.res}/spin_${frameNumberData.frameString}.jpg`;
  const compassAngle = -(angles.rotation - angles.compassOffset) % 360;

  const getCurrentItem = () => {
    multipleItems.every(function (thisItem) {
      if (thisItem.slug === siteId) {
        setCurrentItem(thisItem);
        return false;
      }
      return true;
    });
  };

  useEffect(() => {
    if (multipleItems) getCurrentItem();
  }, [multipleItems]);

  useEffect(() => {
    if (motionFrameRef.current) disableBodyScroll(motionFrameRef.current);
  }, [motionFrameRef.current]);

  useEffect(() => {
    if (globalConfigData) {
      if (globalConfigData.spins[0] != "") {
        let multiple = [];

        globalConfigData.spins.forEach((thisSpin) => {
          let temp = {
            slug: `${thisSpin.id}`,
            url: "",
            title: `${thisSpin.name}`,
          };
          multiple.push(temp);
        });
        setMultipleItems(multiple);
      }
    }
  }, [globalConfigData, location]);

  useEffect(() => {
    if (isDesktopNotMobile) {
      document.documentElement.style.setProperty("--frame-width", 2000);
    } else {
      document.documentElement.style.setProperty("--frame-width", 1000);
    }
  }, [isDesktopNotMobile]);

  useEffect(() => {
    spinConfigData.every(function (arrayItem) {
      if (arrayItem.spinId == siteId) {
        setThisSpinConfig(arrayItem.spinConfigData);
        return false;
      }
      return true;
    });
  }, [siteId]);

  useEffect(() => {
    zoomToPlotMarker();
  }, [selectedPlotMarkerId]);

  useEffect(() => {
    if (!isPreviewPanelOpen) CenterView();
  }, [isPreviewPanelOpen]);

  useEffect(() => {
    document.title = `Siteplan | ${devName}`;
    window.scrollTo(0, 0);
    drawStatusKey();
  }, []);

  useEffect(() => {
    if (scale > 1) {
      enableSpin = false;
      setDisablePan(false);
    } else {
      if (isPreviewPanelOpen) {
        appDispatch({ type: "closePlotPreview" });
      }

      enableSpin = true;
      setDisablePan(true);
    }
  }, [scale]);

  /*
useEffect(() => {

  appDispatch({ type: "closePlotPreview" })

  if(scale >1){
    enableSpin = false
    setDisablePan(false)
  }else{
    enableSpin = true
    setDisablePan(true)
  }
},[scale] )
*/

  useEffect(() => {
    if (thisSpinConfig) {
      setAngles((angle) => ({
        ...angle,
        rotation: 90,
        tilt: parseInt(thisSpinConfig.startTilt),
        compassOffset: 270,
      }));
    }
  }, [thisSpinConfig]);

  useEffect(() => {
    if (isPreviewPanelOpen) {
      setDisablePan(false);
      enableSpin = false;
    } else {
      setDisablePan(true);
      enableSpin = true;
    }
  }, [isPreviewPanelOpen]);

  useEffect(() => {
    return () => {
      //clean up
      clearAllBodyScrollLocks();
    };
  }, []);

  const onMouseDownHandler = (e) => {
    if (!enableSpin) return;

    const eventPosX = e.clientX;
    const eventPosY = e.clientY;

    e.preventDefault();
    setIsPressed(true);
    setPosition((prev) => ({
      ...prev,
      prevX: eventPosX,
      prevY: eventPosY,
      deltaX: 0,
      deltaY: 0,
    }));

    setAngles((angle) => ({
      ...angle,
      lastRotation: angle.rotation,
      lastTilt: angle.tilt,
    }));
  };

  const onMouseMoveHandler = (e) => {
    if (!isPressed || !enableSpin) return;
    const eventPosX = e.clientX;
    const eventPosY = e.clientY;

    e.preventDefault();
    setPosition((prev) => ({
      ...prev,
      deltaX: (eventPosX - prev.prevX) * 0.3,
      deltaY: (eventPosY - prev.prevY) * 0.5,
    }));

    setAngles((angle) => ({
      ...angle,
      rotation: (angle.lastRotation + position.deltaX) % 360,
      tilt: clamp(angle.lastTilt + position.deltaY, 30, 90),
      res: "1k",
    }));
  };

  const onTouchDownHandler = (e) => {
    if (!enableSpin) return;

    const eventPosX = e.touches[0].clientX;
    const eventPosY = e.touches[0].clientY;

    setIsPressed(true);
    setPosition((prev) => ({
      ...prev,
      prevX: eventPosX,
      prevY: eventPosY,
      deltaX: 0,
      deltaY: 0,
    }));

    setAngles((angle) => ({
      ...angle,
      lastRotation: angle.rotation,
      lastTilt: angle.tilt,
    }));
  };

  const onTouchMoveHandler = (e) => {
    if (!isPressed || !enableSpin) return;
    const eventPosX = e.touches[0].clientX;
    const eventPosY = e.touches[0].clientY;

    setPosition((prev) => ({
      ...prev,
      deltaX: (eventPosX - prev.prevX) * 0.3,
      deltaY: (eventPosY - prev.prevY) * 0.5,
    }));

    setAngles((angle) => ({
      ...angle,
      rotation: (angle.lastRotation + position.deltaX) % 360,
      tilt: clamp(angle.lastTilt + position.deltaY, 30, 90),
      res: "1k",
    }));
  };

  const recordSpinPosition = () => {
    if (!enableSpin) return;
    setIsPressed(false);

    setAngles((angle) => ({
      ...angle,
      lastRotation: angle.rotation,
      lastTilt: angle.tilt,
      res: "4k",
    }));
  };

  useEffect(() => {
    //USE THIS FOR FULL 630 FRAMES
    const rotationSteps = 90;
    const n = (rotationSteps * parseInt(-angles.rotation)) / 360;
    const steppedRotationAngle = (360 * n) / rotationSteps;
    const framePanAngle = (parseInt(steppedRotationAngle) + 360) % 360;
    const frameNumberPan = (rotationSteps * parseInt(framePanAngle)) / 360;
    const tiltIntervals = 7;
    const m = (tiltIntervals * parseInt(angles.tilt)) / rotationSteps;
    const steppedTiltAngle = m * (rotationSteps / tiltIntervals);
    const frameNumberTilt = 7 * (1 - parseInt(steppedTiltAngle) / 90.0);
    const spinFrameNumber =
      parseInt(frameNumberPan) * 7 + parseInt(frameNumberTilt);
    const spinFrameString = spinFrameNumber.toString().padStart(2, "0");

    setFrameNumberData((frame) => ({
      ...frame,
      frameNumber: spinFrameNumber,
      frameString: spinFrameString,
    }));
  }, [angles]);

  /*
function ZoomOut(){
  let thisScale = scale
  if(thisScale > 1)
    thisScale = thisScale - 1

  setZoomValue(thisScale)
}*/

  function ZoomOut() {
    if (scale > 1) {
      const factor = Math.log((scale - 1.0) / scale);
      transformComponentRef.current.zoomOut(-factor, 300);
      setScale((scale) => scale - 1);
    }
  }
  /*
function ZoomIn(){
  let thisScale = scale
  if(thisScale < 8)
    thisScale = thisScale + 1

  setZoomValue(thisScale)
}*/

  function ZoomIn() {
    if (scale < 4) {
      const factor = Math.log((scale + 1.0) / scale);
      transformComponentRef.current.zoomIn(factor, 300);
      setScale((scale) => scale + 1);
    }
  }
  /*
function setZoomValue(thisValue){
  const targetScale = parseFloat(thisValue)
  const factor = Math.log(targetScale / scale)
  const { zoomIn, zoomOut } = transformComponentRef.current
  if (targetScale > scale) {
    zoomIn(factor, 300)
  } else {
    zoomOut(-factor, 300)
  }
  setScale(targetScale)
}
*/
  function CenterView() {
    if (transformComponentRef.current != null) {
      const { centerView } = transformComponentRef.current;
      centerView(1, 1000, "easeOut");
      setScale(1);
    }
  }

  function zoomToPlotMarker() {
    if (selectedPlotMarkerId) {
      let thisMarker = document.getElementById(selectedPlotMarkerId);

      if (transformComponentRef.current != null) {
        const { zoomToElement } = transformComponentRef.current;
        let zoomToScale = 2;
        if (scale > 2) zoomToScale = scale;

        if (plotPreviewContent != null) {
          zoomToElement(thisMarker, zoomToScale, 1500, "easeOut");
        }
      }
    }
  }

  function StartInteractiveTimeout() {
    clearInterval(interactiveTimer);
    interactiveTimer = setInterval(function () {
      EnableMarkerInteraction();
    }, 200);
  }

  function EnableMarkerInteraction() {
    clearInterval(interactiveTimer);
    appDispatch({ type: "isPanning", data: false });
  }

  function drawStatusKey() {
    let tempStatusArray = [];
    plotStatuses.forEach(function (aPlotStatus) {
      if (aPlotStatus.name != "Hidden") {
        tempStatusArray.push({
          statusLabel: aPlotStatus.name,
          statusColour: aPlotStatus.color,
        });
      }
    });
    setPlotStatusArray(tempStatusArray);
  }

  function drop(event) {
    const a = currentItem.title.toLowerCase();
    const sitename = a.replaceAll(" ", "-");

    if (!doOnceFlag) {
      doOnceFlag = true;
      goToRoute("/exterior-tour/" + sitename + "/" + event.dropData);
    }
    appDispatch({ type: "isVrpersonActive", data: false });
  }

  function drag(event) {
    appDispatch({ type: "isVrpersonActive", data: true });
    setDisablePan(false);
    enableSpin = false;
  }

  function dragEnded(event) {
    doOnceFlag = false;
    appDispatch({ type: "isVrpersonActive", data: false });
    if (scale > 1) {
      enableSpin = false;
      //setEnablePan({disabled: false})
      setDisablePan(false);
    } else {
      enableSpin = true;
      setDisablePan(true);
      //setEnablePan({disabled: true})
    }
  }

  return (
    <Page title="Site Plan" container={false}>
      <motion.div
        ref={motionFrameRef}
        className="MainWrapper"
        key={"MainWrapper"}
        animate={{
          width: appState.isPreviewPanelOpen ? 70 + "vw" : 100 + "vw",
        }}
        onAnimationComplete={isPreviewPanelOpen ? zoomToPlotMarker : CenterView}
        transition={{
          x: { type: "spring", stiffness: 300, damping: 40 },
        }}
      >
        <React.Fragment>
          <div className="status-container">
            {plotStatusArray.map((thisStatusItem) => (
              <div className="status-item" key={thisStatusItem.statusLabel}>
                <StatusKeyItem
                  label={thisStatusItem.statusLabel}
                  colour={thisStatusItem.statusColour}
                />
              </div>
            ))}
          </div>
        </React.Fragment>

        <TransformWrapper
          ref={transformComponentRef}
          initialScale={scale}
          maxScale={4}
          limitToBounds={true}
          onZoomStop={(e) => {
            setScale(e.state.scale);
          }}
          panning={{ disabled: disablePan }}
          onPanning={(e) => {
            appDispatch({ type: "isPanning", data: true });
          }}
          onPanningStop={(e) => {
            StartInteractiveTimeout();
          }}
          centerOnInit
          onZoom={(e) => {
            setScale(e.state.scale);
          }}
        >
          {({ zoomIn, zoomOut, resetTransform, centerView, ...rest }) => (
            <>
              {!isPreviewPanelOpen ? (
                <div className="zoomControlPanel">
                  <button className="zoomInButton" onClick={() => ZoomIn()}>
                    <ZoomControlPlus />
                  </button>
                  <button className="zoomOutButton" onClick={() => ZoomOut()}>
                    <ZoomControlMinus />
                  </button>
                  <button
                    className="centerSpinButton"
                    onClick={() => CenterView()}
                  >
                    <IconCenter />
                  </button>
                  <DragDropContainer
                    targetKey="vr_marker"
                    onDrop={drop}
                    onDrag={drag}
                    onDragEnd={dragEnded}
                  >
                    <div className="vrperson">
                      <VrPerson />
                    </div>
                  </DragDropContainer>
                </div>
              ) : null}
              <TransformComponent
                wrapperStyle={{
                  width: "100%",
                  height: "100%",
                }}
              >
                <div
                  className="zoomContainer"
                  onMouseDown={onMouseDownHandler}
                  onMouseMove={onMouseMoveHandler}
                  onMouseUp={recordSpinPosition}
                  onTouchStart={onTouchDownHandler}
                  onTouchMove={onTouchMoveHandler}
                  onTouchEnd={recordSpinPosition}
                >
                  <img src={spinImageUrl} alt={spinImageUrl} />
                  <GetSpinDataFrame
                    frameNumber={frameNumberData.frameNumber}
                    spinAssetId={siteId}
                    isNotMobile={isDesktopNotMobile}
                    scale={scale}
                  />
                </div>
              </TransformComponent>
            </>
          )}
        </TransformWrapper>
      </motion.div>
      <div
        className="compass"
        style={{ transform: `rotate(${compassAngle}deg)` }}
      >
        <Compass />
      </div>
      <div className="sub-menu">
        <div className="container">
          {multipleItems && multipleItems.length > 1 && !isPreviewPanelOpen ? (
            <ItemMenu
              items={multipleItems}
              currentItem={currentItem.slug}
              route="360spin"
              label="Siteplans"
            />
          ) : null}
        </div>
      </div>
      {isDesktopNotMobile ? <PlotPreview layout="one-col" /> : null}
      {!isPreviewPanelOpen ? (
        <Controls showRefine hideBackButton />
      ) : (
        <Controls hideRefine hideBackButton />
      )}
    </Page>
  );
}

export default SiteplanSpin;
