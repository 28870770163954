import React, { useState, useContext, useEffect, useRef } from "react"
import StateContext from "../StateContext"
import DispatchContext from "../DispatchContext"
import { priceFormatter } from "../components/Helpers"

import { Squash as Hamburger } from "hamburger-react"
import Accordion from "react-bootstrap/Accordion"
import Form from "react-bootstrap/Form"
import Loading from "../components/Loading"
import FilterIcon from "../components/FilterIcon"

import Nouislider from "nouislider-react"
import "nouislider/distribute/nouislider.css"

import { ReactComponent as ResetIcon } from "../icons/reset-icon.svg"

function RefineMenu(props) {
  const appState = useContext(StateContext)
  const appDispatch = useContext(DispatchContext)
  const stateRef = useRef()
  const wrapperRef = useRef(null)
  
  stateRef.current = appState.filters

  const [rangeLabels, setRangeLabels] = useState([])
  const [activeFilter, setActiveFilter] = useState(0)
  
  const handleCheckboxChange = (filterIndex, valueIndex) => (e) => {
    let newFilters = JSON.parse(JSON.stringify(appState.filters))
    
    newFilters[filterIndex].values[valueIndex].isChecked =
    !newFilters[filterIndex].values[valueIndex].isChecked
    
    //add active state to filter
    if (newFilters[filterIndex].values.some((e) => e.isChecked)) {
      newFilters[filterIndex].active = true
    } else {
      newFilters[filterIndex].active = false
    }
    
    appDispatch({
      type: "setFilters",
      data: newFilters
    })
  }

  // On burger button click
  const handleClick = () => {
    if (appState.isRefineMenuOpen) {
      appDispatch({ type: "hideRefineMenu" })
    } else {
      appDispatch({ type: "hideMenu" })
      appDispatch({ type: "showRefineMenu" })
    }
  }
  
  // Range slider
  const rangeOnSlide = (filterID) => (render, handle, value, un, percent) => {
    let newRangeLabels = [...appState.rangeLabels]
    newRangeLabels[filterID] = value
    appDispatch({
      type: "setRangeLabels",
      data: newRangeLabels
    })
  }
  
  const rangeOnSet = (filterIndex) => (render, handle, value, un, percent) => {

    let newFilters = JSON.parse(JSON.stringify(stateRef.current))
    newFilters[filterIndex].selectedValues = value
  
    // Add active state to filter
    if (
      value[0] !== newFilters[filterIndex].values[0]
      || value[1] !== newFilters[filterIndex].values[1]
    ) {
      newFilters[filterIndex].active = true
    } else {
      newFilters[filterIndex].active = false
    }
    
    appDispatch({
      type: "setFilters",
      data: newFilters
    })
  }

  const useOutsideAlerter = (ref) => {
    function handleClickOutside(event) {
      if (
        appState.isRefineMenuOpen &&
        ref.current &&
        !ref.current.contains(event.target) &&
        !event.target.classList.contains("hamburger-react")
      ) {
        appDispatch({ type: "hideRefineMenu" })
      }
    }

    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside)
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("mousedown", handleClickOutside)
    }
  }
  useOutsideAlerter(wrapperRef)
  
  const countActiveFilters = () => {
    return appState.filters.filter(item => item.active).length
  }

  // Reset all selected checkboxes
  const resetFilter = (filterIndex) => (e) => {
    e.preventDefault()

    let newFilters = JSON.parse(JSON.stringify(stateRef.current))

    // Reset range values
    if (appState.filters[filterIndex].type === "range") {
      newFilters[filterIndex].selectedValues = newFilters[filterIndex].values

    // Remove selected checkboxes
    } else if (appState.filters[filterIndex].type === "checkbox") {
      newFilters[filterIndex].values.map(function (value) {
        value.isChecked = false
        return value
      })
    }
  
    // Remove active state from filter
    newFilters[filterIndex].active = false
    
    appDispatch({
      type: "setFilters",
      data: newFilters
    })
  }

  const resetAllFilters = () => (e) => {
    e.preventDefault()

    let newFilters = JSON.parse(JSON.stringify(stateRef.current))

    // Reset all values
    newFilters.map((filter, index) => {
      if(appState.filters[index]) {
        if (appState.filters[index].type === "range") {
          newFilters[index].selectedValues = newFilters[index].values
        } else if (appState.filters[index].type === "checkbox") {
          newFilters[index].values.map(function (value) {
            value.isChecked = false
            return value
          })
        }

        // Remove active state from filter
        newFilters[index].active = false
      }
    })
    
    appDispatch({
      type: "setFilters",
      data: newFilters
    })

    setActiveFilter(0)
  }
  
  return (
    <>
      {appState.filters && appState.filters.length ? (
        <div className={
          "refine-menu " +
          (appState.isRefineMenuOpen ? "active" : "")
        }>
          <div className={
            "refine-toggle " +
            (appState.isRefineMenuOpen ? "active" : "")
          }>
            <Hamburger
              rounded
              color="#ffffff"
              size={20}
              toggle={handleClick}
              toggled={appState.isRefineMenuOpen}
            />
            {countActiveFilters() ? (
              <span className="refine-toggle__count">{countActiveFilters()}</span>
              ) : null}
          </div>
          <div
          className={
            "off-screen-refine-menu " +
            (appState.isRefineMenuOpen ? "off-screen-refine-menu--is-visible" : "")
          }
          ref={wrapperRef}
          ><div className="off-screen-refine-menu__inner">
            <Accordion defaultActiveKey={1}>
              {appState.filters ?
                appState.filters.map((item, filterIndex) =>
                    <div
                      key={filterIndex}
                      className={
                        "filter " + 
                        (item.active ? "filtered " : "")
                      }
                    >
                      <Accordion.Toggle
                            eventKey={item.id}
                            className={item.active ? "filtered" : null}
                          >
                        <div className="filter__title" onClick={() => setActiveFilter(filterIndex)}>
                          <span className="filter__icon"><FilterIcon name={item.name} /></span>
                          <span className="filter__label">{item.displayName}</span>
                        </div>
                      </Accordion.Toggle>
                      <Accordion.Collapse eventKey={item.id}>
                        <div className={
                          "filter__content " +
                          (activeFilter === filterIndex ? "active" : "")
                        }>
                          <div className="filter__filters">
                            {item.type === "range" ?
                              Number.isInteger(item.values[0]) ? (
                                <>
                                  <Nouislider
                                    range={{
                                      min: item.values[0],
                                      max: item.values[1]
                                    }}
                                    start={item.selectedValues}
                                    step={1000}
                                    onSet={rangeOnSet(filterIndex)}
                                    onSlide={rangeOnSlide(item.id)}
                                    connect
                                    disabled={false}
                                  />
                                  <div className="price-range pb-2">
                                    {priceFormatter(
                                      appState.rangeLabels[item.id][0]
                                    )}
                                    {" - "}
                                    {priceFormatter(
                                      appState.rangeLabels[item.id][1]
                                    )}
                                  </div>
                                </>
                              ) : (
                                <Loading />
                              )
                            : item.type === "checkbox" ?
                              <Form>
                                {item.values.map((item, valueIndex) => {
                                  return (
                                    <Form.Check
                                      key={valueIndex}
                                      label={item.label}
                                      checked={item.isChecked}
                                      value={item.value}
                                      onChange={handleCheckboxChange(
                                        filterIndex,
                                        valueIndex
                                      )}
                                      type="checkbox"
                                      id={`check-${filterIndex}-${valueIndex}`}
                                    />
                                  )
                                })}
                              </Form>
                            : null}
                          </div>
                          {item.active ?
                            <button className="filter__reset" onClick={resetFilter(filterIndex)}>
                              <ResetIcon />
                              Reset
                            </button>
                          : null}
                        </div>
                      </Accordion.Collapse>
                  </div>
                )
              : null}

              {countActiveFilters() > 0 ?
                <button className="filter__reset filter__reset--all" onClick={resetAllFilters()}>
                  <ResetIcon />
                  Reset all
                </button>
              : null}
            </Accordion>
          </div></div>
        </div>
      ) : null}{" "}
    </>
  )
}

export default RefineMenu
