import React, { useEffect, useState, useContext } from "react"
import Page from "../components/Page"
import StateContext from "../StateContext"
import DispatchContext from "../DispatchContext"

import { useParams, Link } from "react-router-dom"
import Loading from "../components/Loading"

import Carousel from "react-bootstrap/Carousel"
import Table from "react-bootstrap/Table"

import { dynamicSort, stringToSlug } from "../components/Helpers"

import Badge from "react-bootstrap/Badge"
import PlotDetails from "../components/PlotDetails"
import CustomFields from "../components/CustomFields"
import Controls from "../components/Controls"

import { ReactComponent as SitePlanIcon } from "../icons/siteplan-icon.svg"

import ReactHtmlParser from "react-html-parser"

function SinglePlot(props) {
  const appState = useContext(StateContext)
  const appDispatch = useContext(DispatchContext)

  const currentPlotID = parseInt(useParams().id)

  const [currentPlotData, setCurrentPlotData] = useState()
  const [currentPlotStatus, setCurrentPlotStatus] = useState()
  const [currentPlotView, setCurrentPlotView] = useState()
  const [currentPlotPhase, setCurrentPlotPhase] = useState()

  useEffect(() => {
    appState.plotsData.filter((el) => {
      if (el.id === currentPlotID) {
        //order images
        let sortedImages = el.plotType.assets.slice().sort(dynamicSort("order"))
        el = { ...el, sortedImages }

        //order dims
        let sortedDims = el.plotType.dimensions
          .slice()
          .sort(dynamicSort("orderValue"))
        el = { ...el, sortedDims }

        setCurrentPlotData(el)

        //get the current plot phase
        console.log(el)
        let phase = null
        if (el.customFields && el.customFields.length > 0) {
          phase = el.customFields.find(
            ({ displayName }) => stringToSlug(displayName) === `phase`
          )
        }
        if (phase) {
          setCurrentPlotPhase(phase.fieldValue)
        } else {
          setCurrentPlotPhase(null)
        }

        let status = appState.plotStatuses.filter((status) => {
          if (status.id === el.plotStatusId) {
            return el
          }
          return null
        })[0]

        setCurrentPlotStatus(status)

        //get the current plot view
        let view = null
        if (appState.plotVRs) {
          view = appState.plotVRs.find(
            ({ plot }) => stringToSlug(plot) === `plot-${el.plotNumber}`
          )
        }

        setCurrentPlotView(view)
      }
      return null
    })
  }, [
    appState.plotStatuses,
    appState.plotVRs,
    appState.plotsData,
    currentPlotID
  ])

  //set current light
  useEffect(() => {
    // console.log("run")
    if (currentPlotData) {
      appDispatch({
        type: "setCurrentLight",
        data: { ID: currentPlotData.lightId, status: "on" }
      })
    }
  }, [currentPlotData])

  if (!currentPlotData)
    return (
      <div className="site-loader">
        <Loading />
      </div>
    )

  return (
    <Page
      title={`Plot ${currentPlotData.plotNumber}`}
      container={true}
      fluid={false}
    >
      <div className="row mt-5">
        <div className="col-md-6">
          <h2 className="plot-name">Plot {currentPlotData.plotNumber}</h2>
          <h4 className="house-name">{currentPlotData.name}</h4>
          <div className="house-description">
            {ReactHtmlParser(currentPlotData.description)}
          </div>

          <PlotDetails item={currentPlotData} status={currentPlotStatus} />
          <CustomFields item={currentPlotData} status={currentPlotStatus} />
          {currentPlotView ? (
            <div className="plot-menu">
              <div className="item">
                <Link
                  to={`/exterior-tour/${
                    currentPlotPhase ? stringToSlug(currentPlotPhase) : "current"
                  }/${currentPlotView.arg}`}
                >
                  <SitePlanIcon /> View on Exterior Tour
                </Link>
              </div>
            </div>
          ) : null}
        </div>
        <div className="col-md-6">
          <div className="carousel-wrap">
            <div className="overlay-badge">
              <Badge
                pill
                variant="light"
                className="mb-2"
                style={{ color: currentPlotStatus.color }}
              >
                {currentPlotStatus.name}
              </Badge>{" "}
            </div>
            <Carousel interval={null} controls={false}>
              {currentPlotData.sortedImages.map((img) => {
                return (
                  <Carousel.Item key={img.assetId}>
                    <img className="d-block w-100" src={img.fileUrl} alt="" />
                    {/* <Carousel.Caption>
                      <p>{img.name}</p>
                    </Carousel.Caption> */}
                  </Carousel.Item>
                )
              })}
            </Carousel>
          </div>

          <p className="small mt-5">
            {currentPlotData.plotType?.disclaimerText}
          </p>

          <Table size="sm" responsive className="mt-4">
            <tbody>
              {currentPlotData.sortedDims.map((item) => {
                return (
                  <tr key={item.id}>
                    <td>{item.name}</td>
                    <td>{item.imperialDimensions}</td>
                    <td>{item.metricDimensions}</td>
                  </tr>
                )
              })}
            </tbody>
          </Table>
        </div>
      </div>
      <Controls />
    </Page>
  )
}

export default SinglePlot
