
import React,{useContext, useState, useEffect} from 'react';
import StateContext from "../StateContext"
import DispatchContext from "../DispatchContext"


function PlotMarkerBP(props) {

  const [divStyle, setDivStyle] = useState()
  const appState = useContext(StateContext)
  const appDispatch = useContext(DispatchContext)

  var thisYPos
  var thisHeightScale

  if(props.containerType == "Site"){
    var a = props.posY * 71.0
    thisYPos = a + 14.5 
    thisHeightScale = 71.0
  }else{
    thisYPos = props.posY * 100
    thisHeightScale = 100.0

  }

var thisWidth = Number((props.markerWidth * 100.0).toFixed(1));
var thisHeight = Number((props.markerHeight * thisHeightScale).toFixed(1));
var thisFontSize = Number((props.markerWidth * 400).toFixed(1));

  const newDivStyle = {
    top: thisYPos + '%',
    left: props.posX * 100.0 + '%',
    width: thisWidth + '%',
    height: thisHeight + '%',
    backgroundColor: props.plotColour,
    fontSize: thisFontSize + 'px'
  }

 useEffect(()=>{
    setDivStyle(newDivStyle)
  },[props.posX, props.posY])


 function PlotMarkerClicked(){
  if(!appState.isPanning)
    //appDispatch({ type: "showPopover", data: props.plotId })
    appDispatch({ type: "showPlotPreview", data: { "plotid": props.plotId, "markerId": props.plotNum }})
  }

  return (
    <button key={props.plotNum} id={props.plotNum} className="plotMarkerBP" onClick={PlotMarkerClicked} style={divStyle}>{props.plotNum}</button>

    )
    

  }


export default PlotMarkerBP
