import React,{ useState, useEffect } from 'react';


function StatusKeyItem(props) {

  const [divStyle, setDivStyle] = useState();

  useEffect(()=>{

    const newDivStyle = {
      backgroundColor: props.colour,
      width: "10px",
      height: "10px"
    }

    setDivStyle(newDivStyle)
  },[props.colour])

  return (
    <>
      <div className="status-icon" style={divStyle}/><div className="status-label">{props.label}</div>
    </>
   )
  }


export default StatusKeyItem