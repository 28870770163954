import React, { useState, useEffect, useContext } from "react";
import { useRouteMatch } from "react-router-dom";
import PlotMarker from "../components/PlotMarker";
import VrMarker from "../components/VrMarker";
import ZoneMarker from "../components/ZoneMarker";
import RoadMarker from "../components/RoadMarker";
import StateContext from "../StateContext";

function GetSpinDataFrame(props) {
  var plotMarkersTempArray = [];
  var vrMarkersTempArray = [];
  var zoneMarkersTempArray = [];
  var roadMarkersTempArray = [];

  const appState = useContext(StateContext);
  const [plotMarkers, setPlotMarkers] = useState([]);
  const [vrMarkers, setVrMarkers] = useState([]);
  const [zoneMarkers, setZoneMarkers] = useState([]);
  const [roadMarkers, setRoadMarkers] = useState([]);
  const [thisSpinData, setThisSpinData] = useState(false);

  const { url, path } = useRouteMatch();

  const { spinMarkerData, filteredHomes, plotStatuses } = appState;
  const { spinAssetId, frameNumber, isNotMobile, scale } = props;

  var frameName;
  var arrayCount;

  useEffect(() => {
    spinMarkerData.every(function (arrayItem) {
      if (arrayItem.spinId == spinAssetId) {
        setThisSpinData(arrayItem.spinData);
        return false;
      }
      return true;
    });

    if (thisSpinData) {
      frameName = "FRAME_" + frameNumber;
      arrayCount = thisSpinData[frameName].length;

      BuildMarkerArray();
      setPlotMarkers(plotMarkersTempArray);
      setVrMarkers(vrMarkersTempArray);
      setZoneMarkers(zoneMarkersTempArray);
      setRoadMarkers(roadMarkersTempArray);
    }
  }, [frameNumber, filteredHomes, spinMarkerData, thisSpinData, spinAssetId]);

  function calculateMarkerPosition(thisX, thisY, thisZ) {
    var normalX = thisX / 4000.0;
    var normalY = thisY / 4000.0;

    var xd = normalX * 100;
    var yd = normalY * 100 + 0.3;
    var zd = thisZ / 100000;

    return {
      x: xd,
      y: yd,
      z: zd,
    };
  }

  function BuildMarkerArray() {
    for (var i = 0; i < arrayCount; i++) {
      for (var key in thisSpinData[frameName][i]) {
        var keyString = key;

        if (keyString.includes("plot")) {
          var n = keyString.indexOf("_");
          var rawPlotNumber = keyString.substring(n + 1);
          //remove leading zeros
          let plotNumber = rawPlotNumber.replace(/^0+/, "");

          //let thisPlotInfo = appState.plotsData.find(plotItem => plotItem.plotNumber == plotNumber)
          let thisPlotInfo = filteredHomes.find(
            (plotItem) => plotItem.plotNumber == plotNumber
          );
          if (thisPlotInfo != null) {
            let thiPlotStatus = plotStatuses.find(
              (statusItem) => statusItem.id == thisPlotInfo.plotStatusId
            );

            var markerDataPosX = parseInt(thisSpinData[frameName][i][key].x);
            var markerDataPosY = parseInt(thisSpinData[frameName][i][key].y);
            var markerDataPosZ = parseInt(thisSpinData[frameName][i][key].dist);

            var newMarkerPositions = calculateMarkerPosition(
              markerDataPosX,
              markerDataPosY,
              markerDataPosZ
            );

            plotMarkersTempArray.push({
              uid: i,
              x: newMarkerPositions.x,
              y: newMarkerPositions.y,
              z: newMarkerPositions.z,
              id: thisPlotInfo.id,
              colour: thiPlotStatus.color,
              num: plotNumber,
            });
          }
        } else if (keyString.includes("vr")) {
          var thisTarget = thisSpinData[frameName][i][key].target;
          var markerDataPosX = parseInt(thisSpinData[frameName][i][key].x);
          var markerDataPosY = parseInt(thisSpinData[frameName][i][key].y);
          var markerDataPosZ = parseInt(thisSpinData[frameName][i][key].dist);

          var newMarkerPositions = calculateMarkerPosition(
            markerDataPosX,
            markerDataPosY,
            markerDataPosZ
          );
          var vrmarkerOffset = 1;

          vrMarkersTempArray.push({
            uid: i,
            url: path,
            x: newMarkerPositions.x,
            y: newMarkerPositions.y - vrmarkerOffset,
            z: newMarkerPositions.z,
            id: thisTarget,
          });
          /* } else if (keyString.includes("zn")) {
          var thisTarget = thisSpinData[frameName][i][key].target;
          var thisName = thisSpinData[frameName][i][key].name;
          var markerDataPosX = parseInt(thisSpinData[frameName][i][key].x);
          var markerDataPosY = parseInt(thisSpinData[frameName][i][key].y);
          var markerDataPosZ = parseInt(thisSpinData[frameName][i][key].dist);

          var newMarkerPositions = calculateMarkerPosition(
            markerDataPosX,
            markerDataPosY,
            markerDataPosZ
          );

          zoneMarkersTempArray.push({
            uid: i,
            url: path,
            x: newMarkerPositions.x,
            y: newMarkerPositions.y,
            z: newMarkerPositions.z,
            name: thisName,
            id: thisTarget,
          });*/
        } else if (keyString.includes("road")) {
          var thisName = thisSpinData[frameName][i][key].road;
          var markerDataPosX = parseInt(thisSpinData[frameName][i][key].x);
          var markerDataPosY = parseInt(thisSpinData[frameName][i][key].y);
          var markerDataPosZ = parseInt(thisSpinData[frameName][i][key].dist);

          var newMarkerPositions = calculateMarkerPosition(
            markerDataPosX,
            markerDataPosY,
            markerDataPosZ
          );

          roadMarkersTempArray.push({
            uid: i,
            x: newMarkerPositions.x,
            y: newMarkerPositions.y,
            z: newMarkerPositions.z,
            name: thisName,
          });
        }
      }
    }
  }

  return (
    <>
      {plotMarkers.map((thisPlotMarker) => (
        <div key={thisPlotMarker.uid}>
          <PlotMarker
            posX={thisPlotMarker.x}
            posY={thisPlotMarker.y}
            posZ={thisPlotMarker.z}
            plotId={thisPlotMarker.id}
            plotNum={thisPlotMarker.num}
            colour={thisPlotMarker.colour}
            isNotMobile={isNotMobile}
            scale={scale}
          />
        </div>
      ))}
      {vrMarkers.map((thisVrMarker) => (
        <div key={thisVrMarker.uid}>
          <VrMarker
            posX={thisVrMarker.x}
            posY={thisVrMarker.y}
            posZ={thisVrMarker.z}
            targId={thisVrMarker.id}
            url={thisVrMarker.url}
            scale={scale}
          />
        </div>
      ))}
      {zoneMarkers.map((thisZoneMarker) => (
        <div key={thisZoneMarker.uid}>
          <ZoneMarker
            posX={thisZoneMarker.x}
            posY={thisZoneMarker.y}
            posZ={thisZoneMarker.z}
            name={thisZoneMarker.name}
            targId={thisZoneMarker.id}
            url={thisZoneMarker.url}
          />
        </div>
      ))}
      {roadMarkers.map((thisRoadMarker) => (
        <div key={thisRoadMarker.uid}>
          <RoadMarker
            posX={thisRoadMarker.x}
            posY={thisRoadMarker.y}
            posZ={thisRoadMarker.z}
            name={thisRoadMarker.name}
            scale={scale}
          />
        </div>
      ))}
    </>
  );
}

export default GetSpinDataFrame;
