
import React,{useContext, useState, useEffect} from 'react';
import StateContext from "../StateContext"
import { Link } from "react-router-dom";

/*ZoneMarkerBP is for the blockplan*/

var name = "";

function ZoneMarkerBP(props) {

const [divStyle, setDivStyle] = useState()
const appState = useContext(StateContext)
const [enableLink, setEnableLink] = useState('')

var thisYPos
var thisHeightScale

  if(props.containerType === "Site"){
    var a = props.posY * 71.0
    thisYPos = a + 14.5 
    thisHeightScale = 71.0
  }else{
    thisYPos = props.posY * 100
    thisHeightScale = 100.0

  }

var thisWidth = Number((props.markerWidth * 100.0).toFixed(1));
var thisHeight = Number((props.markerHeight * thisHeightScale).toFixed(1));
var thisFontSize = Number((props.markerWidth * 150).toFixed(1));

getName(appState.plotContainers);

////get name from targetid
function getName(obj, targetId){

    obj.forEach(function (plotContainerData){
      if (plotContainerData.id === props.targId){
        name = plotContainerData.name;
        return false;
      }
    
    if (plotContainerData.hasOwnProperty('plotContainers')){
      getName(plotContainerData.plotContainers);
    }

  })
    
}
///end

 useEffect(()=>{

  const newDivStyle = {
    top: thisYPos + '%',
    left: props.posX * 100.0 + '%',
    width: thisWidth + '%',
    height: thisHeight + '%',
    backgroundColor: props.plotColour,
    fontSize: thisFontSize + 'px'
  }

    setDivStyle(newDivStyle)
  },[props])


 useEffect(()=>{
    if(appState.isPanning){
      console.log('a ' + props.url)
      setEnableLink(`${props.url}`)
    }else{
      console.log('b ' + props.url + '/blockplan/' + props.targId)
      setEnableLink(`${props.url}/blockplan/${props.targId}`)
    }
  },[appState.isPanning])

 
  return (
    <Link className="zoneMarkerBP" to={enableLink} style={divStyle}>{name}</Link>
  )
}


export default ZoneMarkerBP